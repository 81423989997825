<template>
  <div>
    <v-row>
      <v-col
        md="3"
        sm="6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-card-text class="position-relative">
            <v-avatar
              size="60"
              color="primary"
              style="z-index: 10;"
              class="avatar-center"
            >
              <v-img :src="user.image ? user.image.original_url : require('@/assets/images/avatars/3.png')"></v-img>
            </v-avatar>
            <div class="d-flex justify-space-between flex-wrap pt-2">
              <div class="me-2 mx-5">
                <v-card-title class="pt-0 px-0">
                  {{ user.name }}
                </v-card-title>
                <!--                <v-card-subtitle class="text-xs pa-0">-->
                <!--                  <v-chip-->
                <!--                    :color="user.role.color"-->
                <!--                    small-->
                <!--                  >-->
                <!--                    {{ user.role.description }}-->
                <!--                  </v-chip>-->
                <!--                </v-card-subtitle>-->
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout"
                  >
                    <v-icon>
                      mdi-exit-to-app
                    </v-icon>
                  </v-btn>
                </template>
                <span>اضغط لتسجيل الخروج</span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(statistic, index) in statistics"
        :key="index"
        cols="12"
        md="3"
        sm="6"
      >
        <statistics-card
          :title="statistic.title"
          :color="statistic.color"
          :icon="statistic.icon"
          :statistics="statistic.statistics"
          :show-overlay.sync="statistic.loading"
          :to="statistic.to"
        ></statistics-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import {
  mdiAccountOutline, mdiCar, mdiAccountTie, mdiAlarmLightOutline,
} from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import axios from '@axios'
import auth from '@/store/auth';
import StatisticsCard from '@/components/cards/StatisticsCard.vue';

// import StatisticsCards from '@/components/cards/StatisticsCards.vue'

export default {
  name: 'HomePage',
  components: {
    StatisticsCard,

    // StatisticsCards,
  },
  setup() {
    const { rootThemeClasses } = useVuetify()

    return {
      rootThemeClasses,
    }
  },
  data: () => ({
    // loading: true,
    statistics: {
      active_users: {
        title: 'الاعضاء الفعالين',
        statistics: 0,
        icon: mdiAccountTie,
        color: 'success',
        loading: true,
        to: { name: 'users.active' },
      },
      inactive_users: {
        title: 'الاعضاء غير الفعالين',
        statistics: 0,
        icon: mdiAccountTie,
        color: 'error',
        loading: true,
        to: { name: 'users.inactive' },
      },
    },
  }),
  computed: {
    user() {
      return auth.state.user;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        // this.loading = true;
        const response = await axios.get('/user/statistics')
        Object.keys(response.data)
          .forEach(key => {
            if (this.statistics[key]) {
              this.statistics[key].statistics = response.data[key];
              this.statistics[key].loading = false;
            }
          });
      } finally {
        // this.loading = false
      }
    },
    logout() {
      auth.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: 1rem;
  right: -2rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
